<template>
    <section>
        <ValidationObserver ref="validacion">
            <div class="custom-scroll overflow-auto pt-4 pl-5" :style="entregaProgramada ? 'height: calc(100vh - 140px);' : ''">
                <div class="row mx-0">
                    <div class="col-auto px-0 f-15 text-general">
                        Indique los días y horarios que reciben pedidos el CEDIS y sus {{$config.vendedor}}s
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto px-0 f-15">
                        <el-checkbox v-model="entregaProgramada" :true-label="1" :false-label="0" class="check-green text-general" @change="changeParametros('horarios_uso', 'usoHorarios')" />
                        <span class="ml-2">Seleccione esta opción si el Cedis realiza entregas programadas</span>
                    </div>
                </div>
                <div class="row mx-2 align-items-center mt-3">
                    <div class="col-12 mr-5">
                        <p class="f-13 mx-2 text-general">Mensaje de entrega</p>
                        <el-input v-model="textEntrega" placeholder="Mensaje" class="w-100" size="small" @blur="changeParametros('horarios_texto','textEntrega')" />
                        <span class="f-13 mx-2 font-italic">Este mensaje aparece en el Checkout del vendedor y del cliente</span>
                    </div>
                </div>
                <template v-if="entregaProgramada">
                    <div class="row mx-0 align-items-center mt-5">
                        <div class="col-auto px-0 f-18 text-general f-600">
                            Horario de los cedis
                        </div>
                    </div>
                    <div class="row mx-0 mt-3 align-items-center">
                        <div class="col-auto px-0">
                            <p class="text-general f-14">
                                Horas mínimo de <br /> programación del pedido
                            </p>
                        </div>
                        <div class="col-2">
                            <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Horas mínimo de programación del pedido">
                                <el-input v-model="model.horasCedis" placeholder="Horas" class="w-100" size="small" @blur="changeParametros('franja_programacion_cedis','horasCedis')" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto px-0 ml-4">
                            <p class="text-general f-14">
                                Días máximo de <br /> programación del pedido
                            </p>
                        </div>
                        <div class="col-2">
                            <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Días máximo de programación del pedido">
                                <el-input v-model="model.diasCedis" placeholder="Días" class="w-100" size="small" @blur="changeParametros('franja_maximo_cedis','diasCedis')" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 tab-filter border-bottom mb-4">
                        <div v-for="(dia, index) in diasSemana" :key="index" :class="`filter-space${diaCedisSelect == dia.id ? '-active' : ''} px-3 cr-pointer`" @click="diaCedisSelect = dia.id, isInactive('horarioCedis')">
                            <div v-if="!dia.activo" class="point-red" />
                            {{ dia.dia }}
                        </div>
                    </div>
                    <section class="horario-cedis pl-3 mb-5">
                        <div v-for="(data,index) in model.horarioCedis[diaCedisSelect]" :key="index" class="row mx-0 align-items-center mb-3">
                            <div class="col-2">
                                <el-time-select
                                v-model="data.apertura"
                                size="small"
                                placeholder="Apertura"
                                class="w-100"
                                :picker-options="{
                                    start: '05:00',
                                    step: '00:30',
                                    end: '23:59',
                                    format: 'hh:mm a',
                                    valueFormat:'HH:mm',
                                    minTime: validateTime('apertura','cedis',index)
                                }"
                                />
                            </div>
                            <div class="col-auto px-1 f-14 text-general f-600">
                                -
                            </div>
                            <div class="col-2">
                                <el-time-select
                                v-model="data.cierre"
                                size="small"
                                placeholder="Cierre"
                                class="w-100"
                                :picker-options="{
                                    start: '05:00',
                                    step: '00:30',
                                    end: '23:59',
                                    format: 'hh:mm a',
                                    valueFormat:'HH:mm',
                                    minTime: validateTime('cierre','cedis',index)
                                }"
                                />
                            </div>
                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                <i v-if="model.horarioCedis[diaCedisSelect].length > 1" class="icon-delete-trash f-18 text-general cr-pointer" @click="deleteHorario(data.id, 'cedis', index)" />
                            </el-tooltip>
                        </div>
                        <div class="row mx-0 align-items-center mt-4">
                            <div class="col-auto text-general-red f-500 mr-3 f-14 cr-pointer border br-5" @click="pushHorario('horarioCedis')">
                                Agregar horario
                            </div>
                            <el-checkbox v-model="checkedCedis" class="check-green" @change="changeStatus('cedis', $event)">
                                Inactivar
                            </el-checkbox>
                            <div class="col-auto btn-general text-general-red f-500 mr-3 f-14 cr-pointer ml-3" @click="postFranjas('cedis')">
                                Actualizar horarios del cedis para el día {{ identificarDia('horarioCedis') }}
                            </div>
                        </div>
                    </section>
                    <!--******
                Horarios leecheros cedis
            *******-->
                    <div class="row mx-0 align-items-center mt-5">
                        <div class="col-auto px-0 f-18 text-general f-600">
                            Horario de los {{ $config.vendedor }}s del cedis
                        </div>
                    </div>
                    <div class="row mx-0 mt-3 align-items-center">
                        <div class="col-auto px-0">
                            <p class="text-general f-14">
                                Horas mínimo de <br /> programación del pedido
                            </p>
                        </div>
                        <div class="col-2">
                            <el-input v-model="model.horasTienda" placeholder="Horas" class="w-100" size="small" @blur="changeParametros('franja_programacion_tiendas','horasTienda')" />
                        </div>
                        <div class="col-auto px-0 ml-4">
                            <p class="text-general f-14">
                                Días máximo de <br /> programación del pedido
                            </p>
                        </div>
                        <div class="col-2">
                            <el-input v-model="model.diasTienda" placeholder="Días" class="w-100" size="small" @blur="changeParametros('franja_maximo_tiendas','diasTienda')" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 tab-filter border-bottom mb-4">
                        <div v-for="(dia, index) in diasSemanaTienda" :key="index" :class="`filter-space${diaTiendaSelect == dia.id ? '-active' : ''} px-3 cr-pointer`" @click="diaTiendaSelect = dia.id, isInactive('horarioTiendas')">
                            <div v-if="!dia.activo" class="point-red" />
                            {{ dia.dia }}
                        </div>
                    </div>
                    <section class="horario-cedis-leecheros pl-3 mb-5">
                        <div v-for="(data,index) in model.horarioTiendas[diaTiendaSelect]" :key="index" class="row mx-0 align-items-center mb-3">
                            <div class="col-2">
                                <el-time-select
                                v-model="data.apertura"
                                size="small"
                                placeholder="Apertura"
                                class="w-100"
                                :picker-options="{
                                    start: '05:00',
                                    step: '00:30',
                                    end: '23:59',
                                    format: 'hh:mm a',
                                    valueFormat:'HH:mm',
                                    minTime: validateTime('apertura','tiendas',index)
                                }"
                                />
                            </div>
                            <div class="col-auto px-1 f-14 text-general f-600">
                                -
                            </div>
                            <div class="col-2">
                                <el-time-select
                                v-model="data.cierre"
                                size="small"
                                placeholder="Cierre"
                                class="w-100"
                                :picker-options="{
                                    start: '05:00',
                                    step: '00:30',
                                    end: '23:59',
                                    format: 'hh:mm a',
                                    valueFormat:'HH:mm',
                                    minTime: validateTime('cierre','tiendas',index)
                                }"
                                />
                            </div>
                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                <i v-if="model.horarioTiendas[diaTiendaSelect].length > 1" class="icon-delete-trash f-18 text-general cr-pointer" @click="deleteHorario(data.id, 'tiendas', index)" />
                            </el-tooltip>
                        </div>
                        <div class="row mx-0 align-items-center mt-4">
                            <div class="col-auto text-general-red f-500 mr-3 f-14 cr-pointer border br-5" @click="pushHorario('horarioTiendas')">
                                Agregar horario
                            </div>
                            <el-checkbox v-model="checkedTienda" class="check-green" @change="changeStatus('tiendas', $event)">
                                Inactivar
                            </el-checkbox>
                            <div class="col-auto btn-general text-general-red f-500 mr-3 f-14 cr-pointer ml-3" @click="postFranjas('tiendas')">
                                Actualizar horarios de {{$config.vendedor}}s para el día {{ identificarDia('horarioTiendas') }}
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <ValidationObserver ref="validacion" />
        </validationobserver>
    </section>
</template>

<script>
import Service from '@/services/configurar/admin/cedisHorarios'

export default {
    data(){
        return {
            diaCedisSelect: 1,
            diaTiendaSelect: 1,
            checkedCedis: false,
            checkedTienda: false,
            model: {
                horasCedis: null,
                diasCedis: null,
                horarioCedis: {},
                horasTienda: null,
                diasTienda: null,
                horarioTiendas: {},
            },
            diasSemana: [],
            diasSemanaTienda: [],
            entregaProgramada: 0,
            textEntrega: null,
        }
    },
    computed:{
        idCedis(){
            return this.$route.params.id_cedis;
        }
    },
    mounted(){
        this.asignarDiasHorarios();
        this.getData();
    },
    methods: {
        async asignarDiasHorarios(){
            const dias = [
                { id: 1, dia: 'Lunes', activo: true},
                { id: 2, dia: 'Martes', activo: true },
                { id: 3, dia: 'Miercoles', activo: true},
                { id: 4, dia: 'Jueves', activo: true},
                { id: 5, dia: 'Viernes', activo: true},
                { id: 6, dia: 'Sabado', activo: true},
                { id: 7, dia: 'Domingo', activo: true}
            ];
            const horarios = {
                1:[{ id: null, apertura: null, cierre: null }],
                2:[{ id: null, apertura: null, cierre: null }],
                3:[{ id: null, apertura: null, cierre: null }],
                4:[{ id: null, apertura: null, cierre: null }],
                5:[{ id: null, apertura: null, cierre: null }],
                6:[{ id: null, apertura: null, cierre: null }],
                7:[{ id: null, apertura: null, cierre: null }],
            };
            this.diasSemana = _.cloneDeep(dias);
            this.diasSemanaTienda = _.cloneDeep(dias);
            this.model.horarioCedis = _.cloneDeep(horarios),
            this.model.horarioTiendas = _.cloneDeep(horarios);
        },

        async getData(){
            try {
                const {data} = await Service.getData(this.idCedis);
                this.entregaProgramada = data.horarios.horarios_uso;
                this.textEntrega = data.horarios.horarios_texto;
                // Asignación de parametros
                this.asignarParametros(data);
                // Asignación de días para el cedis
                this.asignarDiasCedis(data.horarios.dias_trabajados_cedis);
                // Asignación de días para la tiendas
                this.asignarDiasTiendas(data.horarios.dias_trabajados_tiendas);
                // Asignación de franjas horarios cedis y tiendas
                this.asignarFranjasHorarias(data.horarios.franjas);
            } catch(e){
                this.error_catch(e)
            }
        },

        asignarParametros(data){
            this.model.horasCedis = data.horarios.franja_programacion_cedis;
            this.model.diasCedis = data.horarios.franja_maximo_cedis;
            this.model.horasTienda = data.horarios.franja_programacion_tiendas;
            this.model.diasTienda = data.horarios.franja_maximo_tiendas;
        },

        asignarDiasCedis(diasCedis){
            diasCedis = JSON.parse(diasCedis);
            for(let i = 0; i < diasCedis.length; i++){
                this.diasSemana[i].activo = diasCedis[i] == 1 ? true : false;
            }
        },

        asignarDiasTiendas(diasTienda){
            diasTienda = JSON.parse(diasTienda);
            for(let i = 0; i < diasTienda.length; i++){
                this.diasSemanaTienda[i].activo = diasTienda[i] == 1 ? true : false;
            }
        },

        asignarFranjasHorarias(franjas){
            if(franjas.length > 0){
                for(let i = 0; i < franjas.length; i++){
                    let horario = {};
                    horario.id = franjas[i].id;
                    horario.apertura = franjas[i].inicio;
                    horario.cierre = franjas[i].fin;
                    if(franjas[i].cedis_tipo == 1){
                        this.model.horarioCedis[franjas[i].dia].push(horario);
                        _.remove(this.model.horarioCedis[franjas[i].dia], function(n){
                            return n.id === null
                        });
                    } else {
                        this.model.horarioTiendas[franjas[i].dia].push(horario);
                        _.remove(this.model.horarioTiendas[franjas[i].dia], function(n){
                            return n.id === null
                        });
                    }
                }
            }
        },

        async changeParametros(column, variable){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                let value = null;
                switch(variable){
                case 'horasCedis':
                    value = this.model.horasCedis;
                    break;
                case 'diasCedis':
                    value = this.model.diasCedis;
                    break;
                case 'horasTienda':
                    value = this.model.horasTienda;
                    break;
                case 'diasTienda':
                    value = this.model.diasTienda;
                    break;
                case 'usoHorarios':
                    value = this.entregaProgramada;
                    break;
                case 'textEntrega':
                    value = this.textEntrega;
                    break;
                }
                const model = {
                    column: column,
                    value: value
                };
                const {data} = await Service.putCedisParametros(this.idCedis, model);
                this.notificacion('', 'Horario actualizado', 'success');
            } catch(e){
                this.error_catch(e)
            }
        },

        async postFranjas(bandera){
            try {
                let franjas = [];
                let dia = null;

                if(bandera == 'cedis'){
                    dia = this.diaCedisSelect;
                    franjas = this.model.horarioCedis[this.diaCedisSelect];
                }else{
                    dia = this.diaTiendaSelect;
                    franjas = this.model.horarioTiendas[this.diaTiendaSelect];
                }

                let valid = franjas.filter((item) => item.apertura == null || item.cierre == null).length;
                if(valid > 0)return this.notificacion('Mensaje','Campos, apertura y cierre no pueden ir vacíos','warning');

                const model = {
                    idCedis: this.idCedis,
                    dia: dia,
                    franjas: franjas,
                    tipo: bandera
                };

                const {data} = await Service.postFranjas(model);
                if(data.exito){
                    if(bandera == 'cedis'){
                        franjas = this.model.horarioCedis[this.diaCedisSelect] = data.new;
                    }else{
                        franjas = this.model.horarioTiendas[this.diaTiendaSelect] = data.new;
                    }
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async pushHorario(column){
            let columnData= column == 'horarioCedis' ? this.model.horarioCedis : this.model.horarioTiendas;
            let diaSelect = column == 'horarioCedis' ? this.diaCedisSelect: this.diaTiendaSelect;
            let data = {};
            data.id = null;
            data.apertura = null;
            data.cierre = null;
            columnData[diaSelect].push(data);
        },

        async deleteHorario(idFranja, column, index){
            let columnData = column == 'cedis' ? this.model.horarioCedis : this.model.horarioTiendas;
            let diaSelect = column == 'cedis' ? this.diaCedisSelect: this.diaTiendaSelect;

            if(columnData[diaSelect].length == 1)return;
            columnData[diaSelect].splice(index, 1);

            if(idFranja){
                try{
                    const {data} = await Service.deleteFranja(idFranja);
                }catch(e){
                    this.error_catch(e)
                }
            }
            this.notificacion('', 'Horario eliminado', 'success');
        },

        changeStatus(column, event){
            let diaSelect = column == 'cedis' ? this.diaCedisSelect : this.diaTiendaSelect;
            let index = column == 'cedis' ? this.diasSemana.findIndex((item) => item.id === diaSelect) : this.diasSemanaTienda.findIndex((item) => item.id === diaSelect);
            if(event){
                column == 'cedis' ? this.diasSemana[index].activo = false : this.diasSemanaTienda[index].activo = false;
            }else{
                column == 'cedis' ? this.diasSemana[index].activo = true : this.diasSemanaTienda[index].activo = true;
            }
            this.updateStatus(column);
        },

        async updateStatus(bandera){
            try {
                let column = null;
                let dias = [];

                if(bandera == 'cedis'){
                    column = 'dias_trabajados_cedis';
                    dias = _.map(this.diasSemana, 'activo');
                }else{
                    column = 'dias_trabajados_tiendas';
                    dias = _.map(this.diasSemanaTienda, 'activo');
                }

                dias[0] = dias[0] ? 1 : 0;
                dias[1] = dias[1] ? 1 : 0;
                dias[2] = dias[2] ? 1 : 0;
                dias[3] = dias[3] ? 1 : 0;
                dias[4] = dias[4] ? 1 : 0;
                dias[5] = dias[5] ? 1 : 0;
                dias[6] = dias[6] ? 1 : 0;

                if(!column && dias.length < 1)return;

                const model = {
                    column: column,
                    value: JSON.stringify(dias)
                };
                const {data} = await Service.putUpdateStatus(this.idCedis, model);
                if(data.exito){
                    this.notificacion('', 'El estado del día se ha actualizado', 'success');
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        isInactive(column){
            let diaSelect = column == 'horarioCedis' ? this.diaCedisSelect : this.diaTiendaSelect;
            let array = column == 'horarioCedis' ? this.diasSemana : this.diasSemanaTienda;
            let data = array.find((item) => item.id === diaSelect);
            if(data.activo){
                return column == 'horarioCedis' ? this.checkedCedis = false : this.checkedTienda = false;
            }
            column == 'horarioCedis' ? this.checkedCedis = true : this.checkedTienda = true;
        },

        identificarDia(column){
            let diaSelect = column == 'horarioCedis' ? this.diaCedisSelect : this.diaTiendaSelect;
            switch(parseInt(diaSelect)){
            case 1:
                return 'Lunes'
            case 2:
                return 'Martes'
            case 3:
                return 'Miercoles'
            case 4:
                return 'Jueves'
            case 5:
                return 'Viernes'
            case 6:
                return 'Sabado'
            case 7:
                return 'Domingo'
            }
        },

        validateTime(value, column, index){
            let diaSelect = column == 'cedis' ? this.diaCedisSelect : this.diaTiendaSelect;
            let horarios = column == 'cedis' ? this.model.horarioCedis[diaSelect] : this.model.horarioTiendas[diaSelect];

            if(index != 0){
                if(value === 'apertura'){
                    return horarios[index-1].cierre;
                } else {
                    return horarios[index].apertura;
                }
            }
            if(value === 'cierre'){
                return horarios[index].apertura;
            }
        },

        async clear(){
            this.diaCedisSelect = 1;
            this.diaTiendaSelect = 1;
            this.checkedCedis = false;
            this.checkedTienda = false;
            this.model.horasCedis = null;
            this.model.diasCedis = null;
            this.model.horarioCedis = {};
            this.model.horasTienda = null;
            this.model.diasTienda = null;
            this.model.horarioTiendas = {};
            this.asignarDiasHorarios();
        },
    }
}
</script>
<style lang="scss" scoped>
.tab-filter{
    .filter-space{
        min-width: 81px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #637381;
        font-size: 14px;
        .point-red{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 11px;
            left: 1px;
            position: absolute;
            background-color: #FF1720;
        }
        &-active{
            @extend .filter-space;
            color: var(--color-general);
            border-bottom: 3px solid var(--color-general);
        }
    }
}
</style>
